import { useState, useEffect } from "react";
import { getUserName } from "../ConfigProvider";

const useSuperadmin = () => {
  const [isSuperadmin, setIsSuperadmin] = useState(false);

  useEffect(() => {
    getUserName().then((userName) => {
      let hadSuperadmingLogin = false;
      if (userName.startsWith("e1c_nico_")) {
        hadSuperadmingLogin = true;
      }
      if (userName.startsWith("e1c_thibault_")) {
        hadSuperadmingLogin = true;
      }
      setIsSuperadmin(hadSuperadmingLogin);
    });
  }, []);

  return isSuperadmin;
};

export default useSuperadmin;
