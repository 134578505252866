import useAdmin from "../../hooks/useAdmin";
import React, { useEffect, useState } from "react";
import AddSensorPopup from "./AddSensorPopup";
import {
  getInventaireCapteurs,
  setInventaireCapteurs,
} from "../../ConfigProvider";
import MissingSensorDetector from "./MissingSensorDetector";
import { getClientList } from "../ClientUtils";
import Filters from "./inventaireCapteursFilters";

function computePrixTTC(prixHT, tva) {
  if (!prixHT || !tva) {
    return "";
  }
  return Number(prixHT) + Number(tva);
}

const SensorLine = function ({
  sensor,
  editSensorCallback,
  ddFournisseur,
  ddModele,
  ddProprietaire,
  filtersState,
}) {
  function getPrixTTCdisplayValue(prixHT, tva) {
    const prixTTC = computePrixTTC(prixHT, tva);
    if (!prixTTC) {
      return "";
    }
    return prixTTC + " €";
  }
  function getTvaDisplayValue(tva) {
    if (!tva) {
      return "";
    }
    return tva + " €";
  }
  function getPrixHTdisplayValue(prixHT) {
    if (!prixHT) {
      return "";
    }
    return prixHT + " €";
  }

  return (
    <tr key={sensor.sensorId}>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.sensorId}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.status}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.statusDetails}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.client}
      </td>
      <td style={{ border: "1px solid black", padding: "2px" }}>
        {sensor.sensorRef}
      </td>

      {filtersState.donneesFinancieres && (
        <>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {ddFournisseur.find((f) => f.key === sensor.fournisseur)?.value}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {ddModele.find((m) => m.key === sensor.modele)?.value}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {ddProprietaire.find((p) => p.key === sensor.proprietaire)?.value}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {sensor.dateAchat}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {getPrixHTdisplayValue(sensor.prix)}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {getTvaDisplayValue(sensor.tva)}
          </td>
          <td style={{ border: "1px solid black", padding: "2px" }}>
            {getPrixTTCdisplayValue(sensor.prix, sensor.tva)}
          </td>
        </>
      )}
      <td
        style={{
          border: "1px solid black",
          padding: "2px",
          fontWeight: "bold",
        }}
      >
        <i
          className="fa fa-edit"
          aria-hidden="true"
          style={{ cursor: "pointer" }}
          onClick={() => editSensorCallback(sensor)}
        ></i>
      </td>
    </tr>
  );
};

const SensorsTable = function ({
  sensors,
  editSensorCallback,
  ddFournisseur,
  ddModele,
  ddProprietaire,
  filtersState,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingRight: "10px",
      }}
    >
      <table style={{ width: "100%", textAlign: "center" }}>
        <thead>
          <style>
            {`
                tr:hover {
                    background-color: #f0f0f0; 
                }
            `}
          </style>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              ID
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Status
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Status detail
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Client
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Ref
            </th>

            {filtersState.donneesFinancieres && (
              <>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Fournisseur
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Modele
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Proprietaire
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Date achat
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Prix HT
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  TVA
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Prix TTC
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {sensors.map((sensor) =>
            SensorLine({
              sensor,
              editSensorCallback,
              ddFournisseur,
              ddModele,
              ddProprietaire,
              filtersState,
            })
          )}
          {filtersState.donneesFinancieres && (
            <tr>
              <td
                colSpan={9}
                style={{
                  padding: "2px",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                Sommes:
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "2px",
                  fontWeight: "bold",
                }}
              >
                {sensors
                  .reduce((acc, s) => {
                    return acc + Number(s.prix);
                  }, 0)
                  .toFixed(2)}
                €
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "2px",
                  fontWeight: "bold",
                }}
              >
                {sensors
                  .reduce((acc, s) => {
                    return acc + Number(s.tva);
                  }, 0)
                  .toFixed(2)}
                €
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "2px",
                  fontWeight: "bold",
                }}
              >
                {sensors
                  .reduce((acc, s) => {
                    return acc + Number(computePrixTTC(s.prix, s.tva));
                  }, 0)
                  .toFixed(2)}
                €
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
///////////////////////////////////////////////////////////////////
const InventaireCapteurs = () => {
  const { canBeAdmin } = useAdmin();
  const [showAddSensorPopup, setShowAddSensorPopup] = useState(false);
  const [sensorToEdit, setSensorToEdit] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtersState, setFiltersState] = useState(getInitialFiltersState());

  function getInitialFiltersState() {
    let clients = getClientList().map((c) => ({ key: c, value: true }));
    clients.unshift({ key: "", value: true });
    return {
      clients: clients,
      status: [
        { key: "en_stock", value: true },
        { key: "installé", value: true },
        { key: "hors_service", value: true },
        { key: "perdu", value: true },
        { key: "autre", value: true },
      ],
      modele: [
        { key: "cy95", value: true },
        { key: "audiomoth", value: true },
        { key: "songmetermicro1", value: true },
        { key: "songmetermicro2", value: true },
      ],
      proprietaire: [
        { key: "every1counts", value: true },
        { key: "biophonia", value: true },
        { key: "louisedelavalliere", value: true },
        { key: "diane", value: true },
      ],
      statusDetails: [],
      donneesFinancieres: true,
    };
  }

  function getAllStatusDetails(currentSensors, currentFiltersState) {
    const filteredSensors = getFilteredSensors(
      currentSensors,
      currentFiltersState
    );
    let statusDetails = [];
    filteredSensors.forEach((s) => {
      if (s.statusDetails && s.statusDetails !== "") {
        statusDetails.push(s.statusDetails);
      }
    });
    const uniqueStatusDetails = statusDetails.filter(
      (s, i, arr) => arr.indexOf(s) === i
    );
    const orderedStatusDetails = uniqueStatusDetails.sort();
    return orderedStatusDetails;
  }

  function filtersChangeCallback(filterKey, newValue) {
    if (newValue === "invert") {
      newValue = filtersState[filterKey].map((c) => ({
        ...c,
        value: !c.value,
      }));
      setFiltersState({ ...filtersState, [filterKey]: newValue });
      return;
    }

    let newFiltersState = { ...filtersState };
    if (filterKey === "donneesFinancieres") {
      newFiltersState = { ...filtersState, donneesFinancieres: newValue };
    } else if (filterKey === "clients") {
      newFiltersState.clients.find((c) => c.key === newValue).value =
        !newFiltersState.clients.find((c) => c.key === newValue).value;
    } else if (filterKey === "status") {
      newFiltersState.status.find((s) => s.key === newValue).value =
        !newFiltersState.status.find((s) => s.key === newValue).value;
    } else if (filterKey === "modele") {
      newFiltersState.modele.find((m) => m.key === newValue).value =
        !newFiltersState.modele.find((m) => m.key === newValue).value;
    } else if (filterKey === "proprietaire") {
      newFiltersState.proprietaire.find((p) => p.key === newValue).value =
        !newFiltersState.proprietaire.find((p) => p.key === newValue).value;
    } else if (filterKey === "statusDetails") {
      newFiltersState.statusDetails.find((s) => s.key === newValue).value =
        !newFiltersState.statusDetails.find((s) => s.key === newValue).value;
    }

    console.log(filterKey);
    if (filterKey === "status") {
      const allStatusDetails = getAllStatusDetails(sensors, newFiltersState);
      console.log(allStatusDetails);

      //remove from statusDetails the ones that are not in allStatusDetails
      newFiltersState.statusDetails = newFiltersState.statusDetails.filter(
        (s) => allStatusDetails.includes(s.key)
      );

      //add only the new ones
      allStatusDetails.forEach((s) => {
        if (!newFiltersState.statusDetails.find((sd) => sd.key === s)) {
          newFiltersState.statusDetails.push({ key: s, value: true });
        }
      });
    }

    setFiltersState(newFiltersState);
  }

  async function fetchSensors() {
    setIsLoading(true);
    const sensors = await getInventaireCapteurs();
    if (!sensors) {
      setSensors([]);
    } else {
      const sensorsJson = JSON.parse(sensors);
      setSensors(sensorsJson);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSensors();
  }, []);

  function getNewSensor() {
    return {
      isnew: true,
      sensorId: "",
      fournisseur: getDdFournisseur()[0].key,
      modele: getDdModele()[0].key,
      proprietaire: getDdProprietaire()[0].key,
      dateAchat: "",
      prix: "",
      tva: "",
      status: "en_stock",
    };
  }

  function newSensorClick() {
    setSensorToEdit(getNewSensor());
    setShowAddSensorPopup(true);
  }

  async function saveSensor(sensor) {
    let sensorsNew = sensors;
    if (sensor.isnew) {
      const doesIdAlreadyExist = sensorsNew.find(
        (s) => s.sensorId === sensor.sensorId
      );
      if (doesIdAlreadyExist) {
        alert(
          "Un capteur avec le sensorId " + sensor.sensorId + " existe déjà"
        );
        return;
      }
      delete sensor.isnew;
      sensorsNew.push(sensor);
    }
    //update the sensors array
    //find the index of the sensor to update
    const indexToUpdate = sensorsNew.findIndex(
      (s) => s.sensorId === sensor.sensorId
    );
    if (indexToUpdate >= 0) {
      sensorsNew[indexToUpdate] = sensor;
    }

    setShowAddSensorPopup(false);
    setIsLoading(true);
    await setInventaireCapteurs(sensorsNew);
    await fetchSensors();
    setIsLoading(false);
  }

  function editSensor(sensor) {
    setSensorToEdit(sensor);

    setShowAddSensorPopup(true);
  }

  function getDdFournisseur() {
    return [
      { key: "ceyomur", value: "CEYOMUR" },
      { key: "labmaker", value: "LABMAKER" },
      { key: "wildlifeacoustics", value: "WILDLIFE ACOUSTICS" },
    ];
  }

  function getDdModele() {
    return [
      { key: "cy95", value: "CY95" },
      { key: "audiomoth", value: "AUDIOMOTH" },
      { key: "songmetermicro1", value: "SONGMETER MICRO1" },
      { key: "songmetermicro2", value: "SONGMETER MICRO2" },
    ];
  }

  function getDdProprietaire() {
    return [
      { key: "every1counts", value: "Every1Counts" },
      { key: "biophonia", value: "Biophonia" },
      { key: "louisedelavalliere", value: "Louise de la Vallière" },
      { key: "diane", value: "Diane" },
    ];
  }

  function getFilteredSensors(
    currentSensors = sensors,
    currentFiltersState = filtersState
  ) {
    let filteredSensors = null;
    if (!currentSensors || !currentFiltersState) {
      return currentSensors;
    }

    filteredSensors = currentSensors.filter((s) => {
      const clientFilter = currentFiltersState.clients.find(
        (c) => c.key === s.client
      );
      return clientFilter ? clientFilter.value : false;
    });

    filteredSensors = filteredSensors.filter((s) => {
      const statusFilter = currentFiltersState.status.find(
        (st) => st.key === s.status
      );
      return statusFilter ? statusFilter.value : false;
    });

    filteredSensors = filteredSensors.filter((s) => {
      const modeleFilter = currentFiltersState.modele.find(
        (m) => m.key === s.modele
      );
      return modeleFilter ? modeleFilter.value : false;
    });

    filteredSensors = filteredSensors.filter((s) => {
      const proprietaireFilter = currentFiltersState.proprietaire.find(
        (p) => p.key === s.proprietaire
      );
      return proprietaireFilter ? proprietaireFilter.value : false;
    });

    return filteredSensors;
  }

  ////////////////////////////////////////////////////////////
  if (!canBeAdmin) {
    return (
      <div className="mainpage">
        <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
      </div>
    );
  }

  return (
    <div className="mainpage">
      {isLoading && (
        <p>
          Chargement des capteurs...
          <i className="fa-solid fa-spinner fa-spin"></i>
        </p>
      )}
      {!isLoading && (
        <div style={{ textAlign: "center", padding: "10px" }}>
          <Filters
            filtersState={filtersState}
            filtersChangeCallback={filtersChangeCallback}
          />
          <h2>Inventaire des capteurs</h2>
          <span style={{ fontWeight: "bold" }}>
            {getFilteredSensors().length}
          </span>{" "}
          capteurs affichés:
          <br />
          <br />
          <SensorsTable
            sensors={getFilteredSensors()}
            editSensorCallback={editSensor}
            ddFournisseur={getDdFournisseur()}
            ddModele={getDdModele()}
            ddProprietaire={getDdProprietaire()}
            filtersState={filtersState}
          />
          <div>
            <button
              style={{
                padding: "5px 10px",
                fontSize: "16px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "30px",
              }}
              onClick={() => newSensorClick()}
            >
              Ajouter un capteur
            </button>
            {showAddSensorPopup && (
              <AddSensorPopup
                sensor={sensorToEdit}
                onCancel={() => setShowAddSensorPopup(false)}
                onSave={saveSensor}
                ddFournisseur={getDdFournisseur()}
                ddModele={getDdModele()}
                ddProprietaire={getDdProprietaire()}
              />
            )}
          </div>
        </div>
      )}
      <hr />
      <MissingSensorDetector inventaireCapteursList={sensors} />
    </div>
  );
};

export default InventaireCapteurs;
